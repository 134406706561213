.home-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #444444;
} 
  
.home-header {
    text-align: center;
    margin-bottom: 40px;
}
  
.home-header h1 {
    font-size: 2.5em;
    color: #444444;
    margin-bottom: 10px;
}
  
.home-header p {
    font-size: 1.2em;
    color: #444444;
}
  
.home-main {
    display: flex;
    justify-content: center;
}

.menu-bar {
    height: 50px;
    padding : 2vh 5vh 2vh 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none; /* Désactive la sélection de texte */
    -webkit-user-select: none; /* Désactive la sélection de texte sur les navigateurs WebKit */
    -moz-user-select: none; /* Désactive la sélection de texte sur Firefox */
    -ms-user-select: none; /* Désactive la sélection de texte sur Internet Explorer */
    top: 0;
    z-index: 20;
    position: sticky;
    background-color: #F9F6EE;
    width: calc(100% - 10vh);
}

